<template>
  <div>
    <Navbar title="Researchers' Resources" :hideSearch="false" />
    <div class="app-body my-5 py-5">
      <div>
        <div class="box">
          <div class="columns is-vcentered">
            <div class="column">
              <p class="title">NYT Search Engine</p>
            </div>
            <div v-if="data && data.length" class="column is-narrow">
              {{ data.length }} Results Fetched
            </div>
            <div class="column is-narrow">
              <b-button
                v-if="data && data.length"
                @click="downloadData"
                type="is-info"
                icon-left="download"
                >Download Data</b-button
              >
            </div>
          </div>
          <div class="columns">
            <div class="column">
              <b-field label="Search Query">
                <b-input v-model="query.searchQuery"></b-input>
              </b-field>
            </div>
          </div>
          <div class="columns">
            <div class="column">
              <b-field label="Start Year">
                <b-input v-model="query.startDate" type="number"></b-input>
              </b-field>
            </div>
            <div class="column">
              <b-field label="End Year">
                <b-input v-model="query.endDate" type="number"></b-input>
              </b-field>
            </div>
          </div>

          <b-button
            v-if="data && data.length"
            @click="getMoreData"
            type="is-info"
            icon-left="plus"
          >
            Load More
          </b-button>
          <b-button
            v-if="data && data.length"
            @click="clear"
            type="is-danger"
            icon-left="close"
            class="ml-2"
          >
            Clear
          </b-button>
          <b-button v-else @click="getData" type="is-success" icon-left="check">
            Submit Query
          </b-button>
        </div>
        <b-table
          :data="data"
          :loading="tableProps.isLoading"
          :striped="tableProps.isStriped"
          :total="tableProps.total"
          :per-page="tableProps.perPage"
          aria-next-label="Next page"
          aria-previous-label="Previous page"
          aria-page-label="Page"
          aria-current-label="Current page"
          paginated
        >
          <b-table-column label="Section" sortable v-slot="props">
            {{ props.row.section_name }}
          </b-table-column>
          <b-table-column label="Title" sortable v-slot="props">
            {{ props.row.headline.main | limitTo(50) }}
          </b-table-column>
          <b-table-column label="Author" sortable v-slot="props">
            {{ props.row.byline.original }}
          </b-table-column>
          <b-table-column label="Weblink" sortable v-slot="props">
            <a :href="props.row.web_url" target="_blank">
              View on NYT
            </a>
          </b-table-column>
        </b-table>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Navbar from "@/components/app/Navbar";
import Footer from "@/components/app/Footer";
import notificationService from "../../services/notificationService";
import axios from "axios";
import jsonexport from "jsonexport";

export default {
  name: "NYT",
  components: { Navbar, Footer },
  data() {
    return {
      query: {
        searchQuery: undefined,
        startDate: undefined,
        endDate: undefined,
        page: 1
      },
      data: [],
      isLoading: false,
      category: "image",
      sort: "title-asc",
      sortName: "Title (A-Z)",
      showSideMenu: false,
      pagination: {
        currentPage: 1,
        totalPages: 0,
        perPage: 5
      },
      tableProps: {
        total: 0,
        isLoading: false,
        isStriped: true,
        sortOrder: "desc",
        defaultSortOrder: "desc",
        page: 1,
        perPage: 15
      }
    };
  },
  computed: {
    isPortrait() {
      if (window.innerWidth <= 768) {
        return true;
      } else {
        return false;
      }
    }
  },
  methods: {
    async getData() {
      this.tableProps.isLoading = true;
      if (this.query.searchQuery && this.query.searchQuery != "") {
        const authKey = "FdALSokIkF4mceaJFxWusJThZAAzW7yV";
        let query =
          "https://api.nytimes.com/svc/search/v2/articlesearch.json?api-key=" +
          authKey +
          "&q=" +
          this.query.searchQuery +
          "&page=" +
          this.query.page;

        if (this.query.startDate && this.query.startDate != "") {
          query = query + "&begin_date=" + this.query.startDate + "0101";
        }
        if (this.query.endDate && this.query.endDate != "") {
          query = query + "&end_date=" + this.query.endDate + "0101";
        }
        await axios
          .get(query)
          .then(res => {
            const docs = res.data.response.docs;
            for (let i = 0; i < docs.length; i++) {
              this.data.push(docs[i]);
            }
          })
          .catch(err => {
            notificationService.error(err.message);
          });
        this.tableProps.isLoading = false;
      } else {
        notificationService.error("Please enter a search query");
      }
    },
    getMoreData() {
      if (this.query.searchQuery && this.query.searchQuery != "") {
        this.query.page = this.query.page + 1;
        this.getData();
      } else {
        notificationService.error("Please enter a search query");
      }
    },
    downloadData() {
      const exportData = this.data.map(doc => {
        return {
          abstract: doc.abstract,
          web_url: doc.web_url,
          snippet: doc.snippet,
          lead_paragraph: doc.lead_paragraph,
          print_section: doc.print_section,
          print_page: doc.print_page,
          source: doc.source,
          headline: doc.headline.main,
          pub_date: doc.pub_date,
          document_type: doc.document_type,
          news_desk: doc.news_desk,
          section_name: doc.section_name,
          subsection_name: doc.subsection_name,
          byline: doc.byline.original,
          type_of_material: doc.type_of_material,
          word_count: doc.word_count,
          uri: doc.uri
        };
      });
      jsonexport(exportData, (err, csv) => {
        if (err) {
          notificationService.error("Error: Unable to export file");
          return console.error(err);
        }
        if (csv) {
          // if export file exist download it
          // creating csv file link from blob
          // and simulating click to download
          const url = URL.createObjectURL(
            new Blob([csv], {
              type: "text/csv"
            })
          );
          const link = document.createElement("a");
          link.href = url;
          // creating file name to include vehicle and date

          const filename = "NYT Search - " + this.query.searchQuery + ".csv";

          link.setAttribute("download", filename);
          document.body.appendChild(link);
          link.click();
        }
      });
    },
    clear() {
      this.data = [];
      this.query.searchQuery = undefined;
      this.query.startDate = undefined;
      this.query.endDate = undefined;
    }
  }
};
</script>

<style lang="scss">
@import "@/assets/styles/main.scss";

.box-anim:hover {
  transition: transform 125ms;
  transform: scale(1.05);
  -webkit-box-shadow: 0 0.5em 1em -0.125em #808080;
  box-shadow: 0 0.5em 1em -0.125em #808080;
}
</style>
